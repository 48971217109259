@use '@angular/material' as mat;
@import "@angular/material/theming";

@import "../partials/styles/style-my-tasks";
@import "../partials/styles/style-tasks-by-me";
@import "../partials/styles/style-stores";
@import "../partials/styles/style-communications";
@import "../partials/styles/style-reports";
@import "../partials/styles/style-repairs";

:root {
--color-primary-contrast: rgb(255, 255, 255);

--color-accent: rgb(255, 64, 129);
--color-accent-contrast: rgb(255, 255, 255);

--color-warn: rgb(244, 67, 54);
--color-warn-contrast: rgb(255, 255, 255);

--btn-primary: #{$btn-primary};
--btn-red: #{$btn-red};
--btn-green: #{$btn-green};
--error: #{$error};

--font-title: 500 18px/26px var(--font);

// Side nav

--sidenav-color: #333;
--sidenav-toolbar-background: #{darken($sidenav-background, 1.5)};
--sidenav-item-background-active: #{darken($sidenav-background, 3)};
--sidenav-item-color: #333;
--sidenav-item-color-active: rgb(var(--text-color));
--sidenav-item-icon-color: grey;
--sidenav-item-icon-color-active: #333;
--sidenav-item-border-color: transparent;
--sidenav-item-border-color-active: --color-primary;
--toolbar-icon-color: theme('colors.primary.500');

  // AppBar
  --app-bar-height: 76px;

  --toolbar-height: 64px;

  --font: "Avenir Next LT Pro",Arial,sans-serif !important;

  --mdc-dialog-supporting-text-font: "Avenir Next LT Pro",Arial,sans-serif !important;
  --mdc-dialog-subhead-font: "Avenir Next LT Pro",Arial,sans-serif !important;

  --mdc-snackbar-container-color: #{$bg};
  --mat-mdc-snack-bar-button-color: #{$dark-primary-text};
  --dark-text: #{$dark-primary-text};
  --mdc-checkbox-intermediate-icon-color: #{$btn-green};
  --mdc-checkbox-intermediate-hover-icon-color: #{$btn-green};
  --mdc-checkbox-selected-icon-color: #{$btn-primary};
  --mdc-checkbox-selected-hover-icon-color: #{$btn-primary};
  --mdc-checkbox-unselected-icon-color: #{$btn-primary};
  --mdc-checkbox-unselected-hover-icon-color: #{$btn-primary};
  --mdc-checkbox-unselected-focus-icon-color: #{$btn-primary};
  
  --mdc-dialog-subhead-size: 20px;

}

.mat-mdc-table {
  .mat-mdc-row,
  .mat-mdc-header-row,
  .mat-mdc-footer-row,
  .mat-mdc-header-cell,
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    min-height: 56px;
    height: 56px;
  }
}


@font-face {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Regular'), url('/assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
}    

@font-face {
    font-family: 'Avenir Next LT Pro';
    font-style: italic;
    font-weight: normal;
    src: local('Avenir Next LT Pro Italic'), url('/assets/fonts/AvenirNextLTPro-It.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: 500;
    src: local('Avenir Next LT Pro Bold'), url('/assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
}   

@font-face {
    font-family: 'Avenir Next LT Pro';
    font-style: normal;
    font-weight: bold;
    src: local('Avenir Next LT Pro Bold'), url('/assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
} 

.sidenav-toolbar {
    border-bottom: 1px solid #E6E6E6;
}

.mat-drawer-side {
    border-right: 1px solid #E6E6E6;
}

.mat-mdc-unelevated-button.mat-primary:hover, 
.mat-mdc-raised-button.mat-primary:hover, 
.mat-mdc-fab.mat-primary:hover, 
.mat-mdc-mini-fab.mat-primary:hover,
.mat-mdc-paginator .mat-mdc-paginator-range-actions .paginator-btn:hover {
    background-color: $btn-background !important;
}

.title {
    font-weight: 700 !important;
}

.toolbar.w-full {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.search-field {
    
    input {
        font-size: 16px !important;
    }
}

.mat-icon.mat-primary {
    color: #{$btn-primary};
}

.mat-badge {
    &.child-tasks-count {
        .mat-badge-content {
            color: #{$light-primary-text};
        }
    }
}

.bg-app-bar {
    .mat-mdc-checkbox {
        label {
            color: #{$light-primary-text} !important;
        }
    }
}

.dashboard-grid {

    // margin-left: 10vw;
    // margin-right: 10vw;
    background-color: #fff;

    .mat-grid-tile {
      border-radius: 3px; 
      cursor: pointer;
      color: #{map-get($vex-primary, 50)};
      font-weight: bold;
      background-color: #{map-get($vex-primary, 900)};


      .mat-icon {
          transform: scale(1.75);
          margin-top: 8px;
        //   width: 24px;
        //   height: 24px;
      }

      .tile-content {
        height: 100%;
        text-align: center;
        padding: 5px;
      }

      .mat-mdc-mini-fab {
        background-color: rgba($color: #FFF, $alpha: 0.2);
      }
    }

    .overview {
        background-color: $page-overview;
    }

    .mytasks {
        background-color: $page-mytasks;
    }

    .assignedbyme {
        background-color: $page-assignedbyme;
    }

    .stores, .my-stores {
        background-color: $page-stores;
    }

    .communication {
        background-color: $page-communication;
    }

    .reports {
        background-color: $page-reports;
    }

    .plan-graph {
        background-color: $page-plan-graph;
    }

    .link {
        background-color: $page-link;
    }

    .daily-info {
        background-color: $page-daily-info;
    }

    .phone-book {
        background-color: $page-phone-book;
    }

    .repairs {
        background-color: $page-repairs;
    }

    .add-order {
        background-color: $page-add-order;
    }

    .deposit-card {
        background-color: $page-deposit-card;
    }

    .unknown {
        background-color: $page-unknown;
    }

    .intranet {
        background-color: $page-intranet;
    }

    .calendar {
        background-color: $page-calendar;
    }

    .weekly-stock-taking {
        background-color: $page-weekly-stock-taking;
    }
}

.logo-text {
    // background-image: url('/src/assets/logo.png');
    height: 24px;
    width: 85px;
    background-size: contain;
    background-repeat: no-repeat;
    &::after
    {
        @apply text-primary;
        padding-left: 32px;
        font-size: 18px;
        font-weight: bold;
        content: 'CRS';
    }
}


.zoom-icon {
    position: absolute;
    top: -16px;
    right: -11px;
    font-size: 24px;

    background-color: rgba(0,0,0,0.5);
    color: white;
    border-radius: 3px;
    height: 34px;
    padding: 0px 5px;
    margin: 0;
}

.files-container {
    flex-direction: row;
    display: flex;

    .remove-button {
        position: absolute;
        top: 4px;
        right: 5px;
        transform: scale(2);
        // display: none;
        color: var(--btn-red);
    }
}

.activity-photo-container {

    display: flex;
    flex-direction: row;
    flex: 0 1 0%;
    flex-wrap: wrap;

    .activity-photo {
        @extend .task-file-wrap;
        flex: 0 1 100px;
        max-height: 200px;
        margin: 5px 5px !important;
        
        .activity-file-wrap
        {
            .photo-wrapper {
                width: 135px;
                height: 135px;
                display: flex;
                justify-content: center;
            }

            .description {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 115px;
                font-size: 12px;
            }
        }
    }
}

.photo-row {
    min-height: 50px;
    max-height: 100px;
    display: flex;
    flex: 1 0 50%;
    padding-top: 0;

    .photo-add-btn {
        max-height: 80px;
        max-width: 80px;
        color: var(--text-secondary);
        position: relative;
    }

    .photo-thumbnail {
        max-height: 80px;
        max-width: 80px;
        flex-direction: column;
        margin: 1px;
        padding: 5px;
        margin-bottom: 15px;
        padding-bottom: 75px;
        img {
            margin: auto;
            height: 100px;
            width: 150px;
        }
        position: relative;

        .time {
            font-size: 10px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .task-file-wrap {

        &.photo-thumbnail {
            //padding: 5px;
            // padding-bottom: 48px;
            //margin-bottom: 0;
            //max-height: 100px;
            //max-width: 115px;
            //min-height: 80px;
            min-height: 125px;
            min-width: 100px
        }

        .time {
            font-size: 10px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.task-file-wrap {
    flex-direction: row;
    display: flex;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 4px;
    padding: 5px;
    background: #fff;
    cursor: pointer;

    .mat-icon {
        font-size: 18px;
        opacity: .6;
    }

    .name {
        flex-grow: 2;
        font-weight: 700;
        opacity: .6; 
    }

    .time {
        opacity: .6; 
        font-size: 10px;
        white-space: nowrap;
        text-align: center;
    }

    .description {
        opacity: .5; 
    }

    .action {
        font-weight: 700;
        color: #00B5E6;
        text-align: right;
    }

}

/* Task detal files tab */
#file-container {
    padding: 5px 10px;

    .files-header {
        margin: 10px 0;
    }

    .subtask-name {
        padding-top: 25px;
    }

    .photo-row {
        max-height: 130px;
    }

    .activity-photo-container {
        .activity-photo {
            padding: 5px;
            margin: 0 !important;
        }
    }
}

.progress-element {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba($color: #aaa, $alpha: 0.4);
}

.photo-uploading-text {
    display: none;
}

.progress-container {
    position: relative;
}

.task-file-wrap:hover {
    .name {
        opacity: 1; 
    }
}

.mat-mdc-dialog-container  {
    prompt-dialog-component {
        padding: 20px;
    }
}

.cdk-overlay-pane {
    // box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
    // background-color: var(--mdc-theme-surface, #fff);
    color: var(--mdc-theme-on-surface, #000);
    border-radius: 0 0 4px 4px;
}

.attachment-row {
    min-height: 50px;
    max-height: 80px;
    display: flex;
    flex: 1 0 50%;
    padding-top: 0;

    .attachment-add-btn {
        max-height: 80px;
        max-width: 90px;
        color: var(--text-secondary);
    }

    .attachment-thumbnail {
        padding: 5px;
        height: 57px;
        padding-top: 0px;
        overflow: hidden;
        position: relative;
        max-width: 80px;
        text-overflow: ellipsis;

        &:hover {
            .remove-button {
                display: block;
            }
        }
    }

    .file-name {
        min-height: 17px;
        height: 17px;
        display: block;
        line-height: 19px;
    }
}

.pictureView {
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1001;
    height: 100%;

    .imageContainer {
        height: calc(100% - 70px);
        margin: auto;
        position: relative;

        img {
            margin: auto;
            padding: 10px;
            max-height: 100%;
        }

        .max-btn, .min-btn {
            content: url('https://api.iconify.design/ic:twotone-aspect-ratio.svg?height=24');
            position: fixed;
            top: calc(10px + 1vw);
            right: calc(10px + 1vw);
            display: block;
            color: white;
            min-height: 20px;
            min-width: 20px;
            cursor: pointer;
            background-color: rgba($color: #FFF, $alpha: 0.4);
            border-radius: 5px;
            padding: 3px;
        }

        .min-btn {
            content: url('https://api.iconify.design/ic:sharp-aspect-ratio.svg?height=24');
            display: none;
        }
    }

    .pictureContainer {
        width: 90vw;
        height: calc(90vh - 42px);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-left: 5vw;
        margin-top: 5vw;
    }

    .closeButton {
        color: var(--btn-primary);
        height: 70px;
        text-align: center;
        font-size: 22px;
        position: absolute;
        right: 5vw;
        bottom: 0;
        padding-top: 15px;
        cursor: pointer;
    }

    .deleteButton {
        color: var(--btn-red);
        height: 70px;
        text-align: center;
        font-size: 22px;
        position: absolute;
        left: 5vw;
        bottom: 0;
        padding-top: 15px;
        cursor: pointer;
    }
}

task-table {

    .task-row {
        cursor: pointer;
        padding-top: 1em;

        &:hover {
            background-color: fade-out($color: #aaa, $amount: 0.7);
        }

        .task-item-status {
            padding-left: 0 !important;
        }

        .row-arrow {
            color: #00B5E6;
        }

        .mat-divider {
            margin-top: .8em;
        }

        .task-item-infoline {
            color: #757575;
        }

        .task-item-priority {
            padding-left: 15px;
            position: relative;
        }

        .task-item-priority:before {
            content: '';
            display: block;
            width: 3px;
            height: 3px;
            background: #000;
            border-radius: 50%;
            position: absolute;
            top: 7px;
            left: 0;
        }

        .task-item-priority.high {
            color: #FF942D;
        }

        .task-item-priority.critical {
            color: #BC3A42;
        }

        .task-item-date {
            font-weight: 700;
            margin-right: 5px !important;
        }
    }

}

.mat-mdc-paginator {
    .mat-mdc-paginator-page-size {
        margin-right: 0;
    }

    .mat-mdc-paginator-range-actions {
        .paginator-btn {
            background-color: $bg;
            margin: 3px;
            &.valid {
              color: $bg;
              background-color: $btn-primary;
            }
            &.inactive {
                background-color: lighten($btn-primary, 5);
            }
            &.disabled {
                background-color: lighten($btn-primary, 5);
            }
        }
    }
}

.logo-wrapper {
    width: 200px !important;
}

.mat-mdc-slide-toggle.mat-checked {
    .mat-mdc-slide-toggle-bar {
        background-color: lighten($btn-green, 30);
    }
    .mat-mdc-slide-toggle-thumb {
        background-color: $btn-green;
    }
}

.mat-mdc-checkbox-indeterminate, .mat-mdc-checkbox-checked {
    &.mat-accent {
        .mat-mdc-checkbox-background {
            background-color: $btn-green;
        }
    }
}

.mat-mdc-raised-button {
    &.mat-accent {
            background-color: $btn-red !important;
            color: $light-primary-text !important;
    }
}

.planning-grid {
    .mat-grid-tile {
        font-size: 18px;
        font-weight: bold;
    }
    .tile-highlight {
        font-size: 1.5em;
    }
}

.toolbar {
    //background: #F3F4F7;
    box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);

    button {
        .mat-icon {
            color: #000;
        }
    }
}


.content {
    background: #fff;
}

.sidenav-toolbar button {
    display: none;
}

.uni-list {
    .bg-app-bar {
        padding-top: 1em;
        padding-bottom: 1em;
        height: auto !important;

        .title {
            color: #fff;
            font-weight: 700;
        }
    }

    .task-row {
        cursor: pointer;
        padding-top: 1em;

        &:hover {
            background-color: fade-out($color: #aaa, $amount: 0.7);
        }

        .row-arrow {
            color: var(--background-app-bar);
            padding-right: 15px;
        }

        .mat-divider {
            margin-top: .8em;
        }

        .task-item-infoline {
            color: #757575;
        }

        .task-item-priority {
            padding-left: 15px;
            position: relative;
        }

        .task-item-priority:before {
            content: '';
            display: block;
            width: 3px;
            height: 3px;
            background: #000;
            border-radius: 50%;
            position: absolute;
            top: 7px;
            left: 0;
        }

        .task-item-priority.high {
            color: #FF942D;
        }

        .task-item-priority.critical {
            color: #BC3A42;
        }

        .task-item-date {
            font-weight: 700;
        }
    }
}

.store-list {
    .bg-app-bar {
        padding-top: 1em;
        padding-bottom: 1em;
        height: auto !important;

        .title {
            color: #fff;
            font-weight: 700;
        }
    }

    .task-row {
        cursor: pointer;
        padding-top: 1em;

        &:hover {
            background-color: fade-out($color: #aaa, $amount: 0.7);
        }

        .row-arrow {
            color: var(--background-app-bar);
            padding-right: 15px;
        }

        .mat-divider {
            margin-top: .8em;
        }

        .task-item-infoline {
            color: #757575;
        }

        .task-item-priority {
            padding-left: 15px;
            position: relative;
        }

        .task-item-priority:before {
            content: '';
            display: block;
            width: 3px;
            height: 3px;
            background: #000;
            border-radius: 50%;
            position: absolute;
            top: 7px;
            left: 0;
        }

        .task-item-priority.high {
            color: #FF942D;
        }

        .task-item-priority.critical {
            color: #BC3A42;
        }

        .task-item-date {
            font-weight: 700;
        }
    }
}

.bg-app-bar {
    box-shadow: 0 8px 8px -4px inset rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 8px 8px -4px inset rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 8px 8px -4px inset rgba(0, 0, 0, 0.1);

    .title {
        color: #fff;
        font-weight: 700;
    }
}

.tasks-list {

    .task-row {
        cursor: pointer;
        padding-top: 1em;
        position: relative;

        &:hover {
            background-color: fade-out($color: #aaa, $amount: 0.7);
        }

        .row-arrow {
            color: var(--background-app-bar);
            padding-right: 15px;
        }

        .mat-divider {
            margin-top: .8em;
        }

        .task-item-infoline {
            color: #757575;
        }

        .task-item-priority {
            padding-left: 15px;
            position: relative;
        }

        .task-item-priority:before {
            content: '';
            display: block;
            width: 3px;
            height: 3px;
            background: #000;
            border-radius: 50%;
            position: absolute;
            top: 7px;
            left: 0;
        }

        .task-item-priority.high {
            color: #FF942D;
        }

        .task-item-priority.critical {
            color: #BC3A42;
        }

        .task-item-date {
            font-weight: 700;
        }
    }
}

task-activities {
    .mat-mdc-card {
        //border: 1px solid #ccc;
        margin-bottom: 10px;
        padding: 0 !important;
    }
}

.star-icons {
    transform: scale(0.8);
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
}

.small-tenant-logo {
    background-image: url('/assets/logo-small.png');
    height: 20px;
    width: 90px;
    margin-left: 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

.footer {
    box-shadow: 0 -8px 8px -4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 -8px 8px -4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 -8px 8px -4px rgba(0, 0, 0, 0.1);
    height: 54px !important;   
    .footer-copy {
        text-align: center;
        color: #bbb;
    }
}

.footer > div > div {
    min-width: 50px;
}
.footer > .footer {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.risk {
    padding: 0px 4px;
      font-weight: bold;
      border: 1px solid $btn-primary;
      border-radius: 3px;
      margin: -1px 8px;
      flex: 0 0 28px;
      text-align: center;
  
    &.N {
      color: $btn-primary;
      border-color: $btn-primary;
      display: none; // Should not be visible
    }
  
    &.R0 {
        color: rgba($color: $btn-red, $alpha: 0.7);
        border-color: rgba($color: $btn-red, $alpha: 0.7);
    }

    &.R1 {
      color: rgba($color: $btn-red, $alpha: 0.8);
      border-color: rgba($color: $btn-red, $alpha: 0.8);
    }
  
    &.R2 {
      color: rgba($color: $btn-red, $alpha: 0.9);
      border-color: rgba($color: $btn-red, $alpha: 0.9);
    }
  
    &.R3 {
      color: rgba($color: $btn-red, $alpha: 1);
      border-color: rgba($color: $btn-red, $alpha: 1);
    }
  
    &.R4 {
      color: rgba($color: $btn-red, $alpha: 1);
      border-color: rgba($color: $btn-red, $alpha: 1);
      border-width: 2px;
    }
  }

.filter-trigger, .sort-trigger {
    color: #fff;
    padding-top: 5px;

    .mat-icon {
        font-size: 24px;
    }
}

.mat-step-header {
    .mat-step-icon {
        background-color: #{map-get($vex-primary, 900)};
    }

    .mat-step-icon-selected {
        background-color: #ff4081;
    }

    .mat-step-icon-state-edit {
        background-color: fade-out($color: #333, $amount: 0.7);
        
    }
}

.form-line {
    padding-top: 12px;
    padding-bottom: 12px;
}

.bg-app-bar.sticky {
    position: fixed !important;
    top: var(--toolbar-height) !important;
    left: 0 !important;
    width: 100%  !important;
    z-index: 99;
}

.ball-scale-multiple
    {
    

    &.blue  {

        position: relative;
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
        z-index: calc(var(--footer-z-index) + 1);
        top: 20px;
        min-height: 60px;

        & > div:nth-child(2) {
            -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
        }

        & > div:nth-child(3) {
            -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
        }

        &.table {
            > div {
                margin-top: calc(50vh - 220px); 
            }
        }

        & > div {
            background-color: var(--btn-primary);
            border-radius: 100%;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            position: absolute;
            left: calc(50% - 25px);
            top: 0;
            opacity: 0;
            margin: 0;
            width: 60px;
            height: 60px;
            -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
            animation: ball-scale-multiple 1s 0s linear infinite;
        }

    }
}

vex-toolbar-notifications {
    .button {
        cursor: pointer;

        &.active {
        background: var(--background-hover);
        }

        .mat-badge-content {
        right: 2px !important;
        top: 2px !important;
        background-color: red;
        color: white;
        }
    }
}


@media screen and (min-width: 1280px) {
    .bg-app-bar.sticky {
        position: fixed !important;
        top: var(--toolbar-height) !important;
        left: var(--sidenav-width)  !important;
        width: calc(100% - var(--sidenav-width))  !important;
        z-index: 99;
    }


    .footer {
        width: calc(100% - 280px);
    }
}

@media screen and (min-width: 768px) {
    .tiles-wrapper {
        max-width: 700px; 
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .dashboard-grid {
        font-size: 13px;
    }
    .cdk-overlay-pane {
        max-width: 100vw !important;

        .mat-mdc-dialog-container {
            padding: 15px;
            padding-right: 5px;
            overflow: hidden;
        }
    }
    task-detail .mdc-tab__text-label .mat-icon {
        display: none;
    }
    task-detail .rating-input > div {
        padding-bottom: 50px;
    }
    task-detail .rating-input button-rating {
        position: absolute;
        bottom: 5px;
    }
    task-detail .rating-input label {
        flex: 1 1 75%;
    }
    task-detail .rating-input .star-icon {
        flex: 1 1 25%;
    }
    .dropdown .dropdown-content {
        padding: 15px !important;
        overflow: auto;
        max-height: 50vh;
    }
    task-list-filter mat-form-field {
        width: 100%;
    }
}

/***
****
**** General
****
***/

task-table .task-row:nth-child(even),
.tasks-list .task-row:nth-child(even),
.store-list .task-row:nth-child(even),
.mat-mdc-card-content > div > task-input:nth-child(even),
.contact-card:nth-child(odd),
.cal-days > div:nth-child(even),
.cal-month-view .cal-open-day-events > div:nth-child(even) {
    background-color: $odd-row;
}
task-table .task-row .task-item-status,
.tasks-list .task-row .task-item-status,
.store-list .task-row .task-item-status {
    padding-left: 10px !important; /* styles.scss:6373 */
    min-width: 60px;
}

.no-results {
    text-align: center;
    min-height: 50px;
    width: 100%;
    min-width: 100%;
    height: 50px;
    vertical-align: middle;
}

.mat-divider {
    border-top-color: #E6E6E6 !important;
}
.mdc-tab-indicator {
    &__content {
        border-color: var(--btn-primary);
        border-top-width: 3px !important;
    }
}
.tasks-list-title {
    text-align: center;
    font-size: 13px;
    color: #808080;
    border-bottom: 1px solid #E6E6E6;
}

.contact-deleted {
    font-style: italic;
    color: var(--text-secondary);
    .mat-icon {
        margin-top: -5px;
    }
}

.task-nr {
    opacity: 0.6;
    font-weight: normal;
}

.rounded-full {
    border-radius: 3px !important;
}
.toolbar .mat-mdc-form-field-label {
    display: none !important;
}

.toolbar vex-toolbar-user {
    flex: none !important;
    margin-left: 5px;
}
/* store-list */

.store-list {
    .task-row {
        padding-left: 15px;

        .task-item-date {
            color: #68B94D;
        }

        .mat-divider {
            margin-left: -15px;
        }
    }

}

.mat-mdc-list-base .mat-mdc-list-item {
    font-size: 14px !important;
}
.contact-card {
    padding: 15px;
}
.contact-phone span,
.contact-mail span {
    padding-top: 5px !important;
}
.contact-phone {
    color: #68B94D;
    font-weight: 700;
}
.contact-job {
    color: #959595;
}
.general-contact {
    padding: 15px;
}
.general-contact-mail {
    margin-bottom: 15px;
}
.general-contact-phone {
    color: #68B94D;
    font-weight: 700;
}

.contact-detail-image {
    padding: 15px;
}
/* calendar */

.calendar {
    .headline {
        display: flex;
        flex: 1 1 0%;
    }
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.2em !important;
    opacity: 1;
    font-weight: 700;
}
.cal-month-view .cal-events {
    display: none !important;
}
.cal-month-view .cal-day-cell {
    min-height: auto !important;
}
.cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: #E6E6E6 !important;
}
.cal-month-view .cal-days .cal-cell-row {
    border-bottom-color: #E6E6E6 !important;
}
.cal-month-view .cal-day-badge {
    margin-top: 10px !important;
}
.cal-month-view .cal-open-day-events {
    background-color: #fff !important;
    border-bottom: 1px solid #E6E6E6 !important;
    box-shadow: none !important;
    padding: 0 !important;
}
.cal-month-view .cal-open-day-events > div {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.cal-month-view .cal-open-day-events > div + div {
    margin-top: 1px !important;
}
.cal-month-view .cal-day-badge {
    background-color: #00B5E6 !important;
}
.cal-month-view .cal-cell-row .cal-cell.cal-open {
    background: #fff !important;
    border: 2px solid #00B5E6 !important;
    box-shadow: none !important;
}
.cal-month-view .cal-header {
    border: 1px solid #E6E6E6 !important;
}
calendar-main-component .bg-contrast-black {
    background-color: #00B5E6 !important;
    opacity: 1 !important;
}
.uni-list {
    padding-top: 0 !important;
}

.mat-mdc-card-content > div > task-input {
    border-bottom: 1px solid #E6E6E6;
    padding: 10px 0px 5px 15px;
}

.mat-mdc-dialog-title h1,
.mat-mdc-dialog-title h2 {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 24px;
}
.mat-mdc-dialog-title .headline {
    opacity: 1;
}
.mat-mdc-dialog-title h5 {
    color: #959595;
}
.images-list {
    padding: 15px;
}


.photo-row {
    &.required {
        .photo-add-btn {
            .photo-add-text {
                color:var(--error);
                &::after {
                    content: '*';
                }
            }
        }
    }
}


/***
****
**** Mobile (iPhone 6/X)
****
***/

@media screen and (max-width: 767px) {
    /* toolbar */
    .toolbar {
        height: 54px !important; /* var(--toolbar-height) */
    }
    .toolbar .small-tenant-logo {
        width: 60px; /* style.scss:6649 */
        height: 38px;
        margin-left: 3px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .px-4 {
        padding-left: .2rem !important;
        padding-right: .2rem !important;
    }
    /* app-bar */
    .bg-app-bar.sticky {
        top: 54px !important; /* style.scss:6702 */
        height: 54px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .search-field {
        height: 34px !important;
    }
    /* dashboard */
    .dashboard-grid {
        margin-left: 10px;
        margin-right: 10px;
    }
    /* tasks-list */
    task-table .task-row task-item > div > div,
    .tasks-list .task-row task-item > div > div,
    .store-list .task-row task-item > div > div {
        margin-right: 10px !important; /* inline */
    }
    task-table .task-row task-item > div > div:last-child,
    .tasks-list .task-row task-item > div > div:last-child,
    .store-list .task-row task-item > div > div:last-child {
        margin-right: 0 !important; /* inline */
        flex: 1 0 110px !important;
    }
    .content {
        margin-top: 54px !important;
    }
    .tasks-list,
    .store-list {
        padding-top: 54px !important;
    }
    task-table .task-row task-item .task-item-infoline,
    .tasks-list .task-row task-item .task-item-infoline {
        display: block !important;
    }
    task-table .task-row .task-item-priority,
    .tasks-list .task-row .task-item-priority {
        padding-left: 0;
    }
    task-table .task-row .task-item-priority:before,
    .tasks-list .task-row .task-item-priority:before {
        display: none;
    }
    .tasks-list .task-row task-item > div > div:last-child span {
        margin-right: 5px !important; /* inline */
    }
    .tasks-list .task-row .row-arrow {
        padding-right: 0;
    }
    .vex-page-layout-content {
        padding-left: 0 !important; /* page-layout.component.scss:60 */
        padding-right: 0 !important;
    }
    .sortIcon {
        color: #808080 !important;
        font-size: 30px !important;
    }
    .sortIcon svg {
        width: auto !important;
        height: auto !important;
    }
    .dropdown {
        width: 100vw;
    }
    .dropdown-heading {
        font-size: 16px !important;
        font-weight: 700 !important;
        color: #000;
    }
    .dropdown-heading-icon {
        display: none !important;
    }
    .dropdown-header {
        padding: 10px 15px !important;
    }
    /* modal */
    .mat-mdc-dialog-container {
        width: 100vw !important;
    }
    task-detail .mat-mdc-dialog-content {
        min-height: 70vh !important;
        max-height: 70vh !important;
    }
    task-detail .mat-mdc-tab-nav-bar,
    task-detail .mat-mdc-tab-header {
        border-bottom: 1px solid var(--foreground-divider);
    }
    task-detail .mat-horizontal-stepper-header-container {
        display: none !important; /* delete before handover */
    }
    task-detail .mat-horizontal-content-container {
        padding: 0 !important;
        max-height: none !important;
    }

    .mat-mdc-dialog-content {
        margin: 0 !important;
        padding: 0 !important;
    }

    .mat-mdc-card-content > div > task-input {
        border-bottom: 1px solid #E6E6E6;
        padding: 10px 0 5px 15px;
    }
    .cdk-overlay-pane .cdk-dialog-container {
        padding: 15px 0 !important;
    }
    .mat-mdc-card-title {
        padding: 0 15px !important;
    }
    .mat-stepper-horizontal {
        padding: 0 !important;
    }
    task-detail .rating-input label {
        padding-bottom: 3px;
    }

    .mat-mdc-slide-toggle,
    .mat-mdc-slide-toggle-content {
        white-space: normal !important;
    }

    .mat-mdc-paginator {
        .mat-mdc-paginator-range-actions {
            .paginator-btn {
                padding: 0px;
                margin: 2px;
                width: 32px;
                height: 32px;
            }

            
            .mat-custom-page {
                display: none;
            }

            .mat-mdc-paginator-range-label {
                margin: 0 15px 0 15px !important;
            }
        }

        .mat-mdc-form-field  {
            height: 30px;
            width: 60px;

            .mat-mdc-form-field-infix {
                padding-top: 8px !important;
            }

            .mat-mdc-form-field-flex {
                height: 30px;
            }
        }
    }

    
}

/***
****
**** Small Mobile (iPhone 5/SE)
****
***/

@media screen and (max-width: 350px) {
    /* general */
    h1 {
        font-size: 24px !important;
    }
    /* toolbar */
    .toolbar.w-full {
        padding-left: 5px !important; /* style.scss:6247 */
        padding-right: 5px !important;
    }
    .toolbar .mat-mdc-form-field {
        max-width: 160px; /* style.scss:6784 */
    }
    .toolbar .px-1 {
        padding: 0 !important; /* tailwind.scss:12347 */
    }
    /* dashboard */
    .dashboard-grid {
        font-size: 12px; /* style.scss:6770 */
    }
    .dashboard-grid .mat-grid-tile .mat-icon {
        transform: scale(1.25); /* style.scss:6263 */
    }
    .dashboard-grid .mat-grid-tile .tile-content {
        padding: 3px; /* style.scss:6268 */
    }
    .footer > .footer {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .bg-app-bar {
        &.input-focused {
            .hide-on-focus {
                display: none;
            }
        }
    }

    .mat-mdc-paginator {
        .mat-mdc-paginator-range-actions {
            .mat-mdc-paginator-range-label {
                //display: none;
            }

            .mat-custom-page {
                display: none;
            }
        }
    } 
}

/***
****
**** Hacks for Chrome on iOS to avoid hide functional buttons under browser bar
****
***/
.chrome-ios {
    task-detail .mat-mdc-dialog-content {
        min-height: 65vh !important;
        max-height: 65vh !important;
    }

    .mat-mdc-dialog-surface {
        height: 95vh;
        position: fixed;
        top: 0;
    }
}