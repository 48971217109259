@use '@angular/material' as mat;
@import "var";
@import "@angular/material/theming";
@import "../partials/_mixins.scss";

:root {

    --color-primary: #{$btn-primary};
    --background-app-bar: #{darken($sidenav-background, 1.5)};

}

body {
    letter-spacing: normal;
}

.mat-mdc-table {
    .mat-mdc-header-row {
        color: var(--text-secondary) !important;
    }
}

.sidenav .mat-drawer-inner-container {
    background: url('/assets/bg_sidenav_light.png') no-repeat 50% 95% !important;
}

.footer {
    background: #F3F4F7;
    
    .footer-back {
        a {
            color: #000;
        }
    }
}

.attendance-btn {
    &.mat-icon.in {
      color: var(--btn-red);
    }
    &.mat-icon.out {
      
    }
  }

.toolbar .mat-mdc-text-field-wrapper{
    border-radius: 4px 4px 0 0 !important;
    padding: .1em .5em !important;
}

