@use '@angular/material' as mat;
@import "@angular/material/theming";

// $sidenav-background: #111 !default;
$sidenav-background: #F1F2F6;

$btn-red: rgb(255, 64, 129);
$btn-primary: rgb(0, 181, 230);
$btn-green: rgb(104, 185, 77);
$btn-green-light: rgb(146, 182, 134);
$btn-green-light-trans: rgba(135, 184, 119, 0.514);
$btn-disabled: rgb(153, 153, 153);

$page-overview: #B4584F;
$page-mytasks: #00B5E6;
$page-assignedbyme: #F2A403;
$page-link: #F2A403;
$page-stores: #78C1DD;
$page-communication: #733CC5;
$page-reports: #68B94D;

$page-plan-graph: #B4584F;
$page-documents: #00B5E6;
$page-daily-info: #F2A403;
$page-phone-book: #78C1DD;
$page-repairs: #733CC5;
$page-add-order: #68B94D;
$page-deposit-card: #00B5E6;
$page-intranet: #B4584F;
$page-calendar: #733CC5;
$page-unknown: #00B5E6;
$page-weekly-stock-taking: #68B94D;
$odd-row: #f2f2f2;

$error: #f44336;
$btn-background: #00AFDB;

$config: mat.define-typography-config(
  $font-family: var(--font),
  $body-1: mat.define-typography-level(14px, 20px, 400, $letter-spacing: -0.006em),
  $body-2: mat.define-typography-level(14px, 24px, 400, $letter-spacing: -0.006em),
  $caption: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $headline-1: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $headline-2: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $headline-3: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $headline-4: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $headline-5: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $headline-6: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $subtitle-1: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $subtitle-2: mat.define-typography-level(12px, 20px, 500, $letter-spacing: 0)
) !default;

$bg: #F5F5F5;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vex-primary: mat.define-palette((
  50: #ecefff,
  100: #ced7ff,
  200: #aebcff,
  300: #8ea1ff,
  400: #758cff,
  500: #00B5E6,
  600: #5570ff,
  700: #4b65ff,
  800: #415bff,
  900: #3048ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #FFF,
    600: #FFF,
    700: #FFF,
    800: #FFF,
    900: #FFF,
  )
)) !default;
$vex-accent: mat.define-palette($mat-pink, A200, A100, A400) !default;

// The warn palette is optional (defaults to red).
$vex-warn: mat.define-palette($mat-red) !default;

$vex-theme-foreground: (
  text: black,
  elevation: $mat-elevation-color,
  divider: rgba(82, 63, 105, 0.06)
) !default;

$vex-theme-background: (
  app-bar: #ebebee
) !default;

// Create the theme object (a Sass map containing all of the palettes).
$vex-theme: (
  primary: $vex-primary,
  accent: $vex-accent,
  warn: $vex-warn,
  is-dark: false,
  foreground: map_merge($mat-light-theme-foreground, $vex-theme-foreground),
  background: map_merge(mat.$light-theme-background-palette, $vex-theme-background),
  // color: (
  //   primary: $vex-primary,
  //   accent: $vex-accent,
  //   warn: $vex-warn
  // ),
  typography: $config,
  density: 0
) !default;

$vex-dark-theme-background: (
  background: lighten($sidenav-background, 5),
  card: $sidenav-background,
  app-bar: darken($sidenav-background, 5),
  dialog: $sidenav-background,
  status-bar: darken($sidenav-background, 5)
) !default;

$vex-dark-theme: (
  primary: $vex-primary,
  accent: $vex-accent,
  warn: $vex-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: map_merge(mat.$dark-theme-background-palette, $vex-dark-theme-background),
) !default;

$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);

$small-tenant-logo: '/assets/logo-small.png'